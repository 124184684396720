import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import styles from "./Navbar.module.css";
import logoWhite from "../assets/arncloud-logo-white.png";

const Navbar = () => {
  const { auth, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout(); // Clear auth context and local storage
    navigate("/"); // Redirect to login page
  };

  return (
    <nav className={styles.navbar}>
      <div>
        <img src={logoWhite} alt="arncloud logo" className={styles.logo} />
      </div>
      <div className={styles.userSection}>
        {auth.token && (
          <>
            <span className={styles.userName}>
              Welcome back, {auth.user?.username || "User"}
            </span>
            <button onClick={handleLogout} className={styles.logoutButton}>
              Logout
            </button>
          </>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
