import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Home.module.css";
import logoWhite from "../assets/arncloud-logo-white.png";
import featureImage1 from "../assets/secure-storage.png";
import featureImage2 from "../assets/easy-access.png";
import featureImage3 from "../assets/reliable.png";

const Home = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      {/* Hero Section */}
      <section className={styles.hero}>
        <img src={logoWhite} alt="arncloud logo" className={styles.logo} />
        <h1 className={styles.title}>The Future of Cloud Storage</h1>
        <p className={styles.subtitle}>
          Secure, reliable, and fast. Your files, always within reach.
        </p>
        <button className={styles.button} onClick={() => navigate("/sign-in")}>
          Get Started
        </button>
      </section>

      {/* Features Section */}
      <section className={styles.features}>
        <div className={styles.feature}>
          <img
            src={featureImage1}
            alt="Secure Storage"
            className={styles.featureImage}
          />
          <h3 className={styles.featureTitle}>Secure Storage</h3>
          <p className={styles.featureDescription}>
            We use state-of-the-art encryption to keep your files safe and
            secure.
          </p>
        </div>
        <div className={styles.feature}>
          <img
            src={featureImage2}
            alt="Easy Access"
            className={styles.featureImage}
          />
          <h3 className={styles.featureTitle}>Easy Access</h3>
          <p className={styles.featureDescription}>
            Access your files anytime, anywhere, on any device.
          </p>
        </div>
        <div className={styles.feature}>
          <img
            src={featureImage3}
            alt="Reliable"
            className={styles.featureImage}
          />
          <h3 className={styles.featureTitle}>Reliable</h3>
          <p className={styles.featureDescription}>
            Enjoy 99.99% uptime and lightning-fast file uploads and downloads.
          </p>
        </div>
      </section>

      {/* Footer Section */}
      <footer className={styles.footer}>
        <p>&copy; 2024 ARNCloud. All Rights Reserved.</p>
      </footer>
    </div>
  );
};

export default Home;
