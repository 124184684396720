import axios from "axios";

const API = axios.create({
    baseURL: "https://cloudapi.arinci.nl/api", // Backend base URL
});

// Add the token to every request if authenticated
API.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

// Auth APIs
export const login = (data) => API.post("/auth/login", data);
export const register = (data) => API.post("/auth/register", data);

// File APIs
export const uploadFile = (formData, onUploadProgress) =>
    API.post("/files/upload", formData, {
        onUploadProgress, // Pass the progress callback
    });
export const listFiles = () => API.get("/files/list");
export const deleteFile = (fileId) => API.delete(`/files/delete/${fileId}`);

// User APIs
export const fetchStorageInfo = () => API.get("/users/storage-info");

// Forgot Password
export const sendForgotPasswordEmail = (data) => API.post("/auth/forgot-password", data);

// Reset Password
export const resetPassword = (data) => API.post("/auth/reset-password", data);

