import React, { createContext, useState } from "react";
import { jwtDecode } from "jwt-decode";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState(() => {
        const token = localStorage.getItem("token");
        if (token) {
            const decoded = jwtDecode(token);
            return {
                token,
                role: decoded.role,
                user: {
                    username: decoded.username,
                    used_storage: decoded.used_storage,
                    max_storage: decoded.max_storage,
                },
            };
        }
        return { token: null, role: null, user: null };
    });

    const login = (token) => {
        const decoded = jwtDecode(token); // Decode the JWT to extract data
        localStorage.setItem("token", token); // Temporarily store token
        setAuth({
            token,
            role: decoded.role,
            user: {
                username: decoded.username,
                used_storage: decoded.used_storage,
                max_storage: decoded.max_storage,
            },
        });
    };

    const logout = () => {
        localStorage.removeItem("token"); // Remove token from local storage
        setAuth({ token: null, role: null, user: null });
    };

    return (
        <AuthContext.Provider value={{ auth, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
