import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { sendForgotPasswordEmail } from "../../services/api"; // API function for sending email
import styles from "./ForgotPassword.module.css";
import logoWhite from "../../assets/arncloud-logo-white.png";

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [countdown, setCountdown] = useState(5); // Countdown timer
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage("");
        setError("");
        setCountdown(5); // Reset countdown
        try {
            await sendForgotPasswordEmail({ email });
            setMessage("Password reset link sent to your email. Redirecting to login in 5 seconds.");
            const timer = setInterval(() => {
                setCountdown((prev) => prev - 1);
            }, 1000);

            // Redirect after 5 seconds
            setTimeout(() => {
                clearInterval(timer); // Clear the interval
                navigate("/sign-in");
            }, 5000);
        } catch (err) {
            setError("Failed to send password reset link. Please try again.");
        }
    };

    useEffect(() => {
        if (countdown <= 0) {
            setCountdown(0); // Prevent negative countdown
        }
    }, [countdown]);

    return (
        <div className={styles.container}>

            <img className={styles.logo} src={logoWhite} alt="arncloud logo" />
            <form className={styles.form} onSubmit={handleSubmit}>
                <h1 className={styles.heading}>Forgot Password</h1>
                {message && (
                    <p className={styles.success}>
                        {message.replace("5 seconds", `${countdown} seconds`)}
                    </p>
                )}
                {error && <p className={styles.error}>{error}</p>}
                {!message && (
                    <>
                        <input
                            type="email"
                            placeholder="Enter your email"
                            className={styles.input}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <button type="submit" className={styles.button}>
                            Send Reset Link
                        </button>
                    </>
                )}
            </form>
        </div>
    );
};

export default ForgotPassword;
