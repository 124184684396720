import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { register } from "../services/api"; // API for registering a user
import styles from "./Register.module.css";
import logoWhite from "../assets/arncloud-logo-white.png";

const Register = () => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    try {
      await register({ email, username, password });
      setSuccess(true);
      setTimeout(() => navigate("/sign-in"), 2000); // Redirect after 2 seconds
    } catch (err) {
      setError(
        err.response?.data?.message || "Registration failed. Please try again."
      );
    }
  };

  return (
    <div className={styles.container}>
      <img className={styles.logo} src={logoWhite} alt="arncloud logo" />
      <form className={styles.form} onSubmit={handleSubmit}>
        <h1 className={styles.heading}>Create Account</h1>
        {error && <p className={styles.error}>{error}</p>}
        {success && (
          <p className={styles.success}>
            Registration successful! Redirecting...
          </p>
        )}
        <input
          type="text"
          placeholder="Username"
          className={styles.input}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <input
          type="email"
          placeholder="Email"
          className={styles.input}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          className={styles.input}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Confirm Password"
          className={styles.input}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
        <button type="submit" className={styles.button}>
          Register
        </button>
        <p className={styles.signInLink}>
          Already have an account? <a href="/sign-in">Sign in</a>
        </p>
      </form>
    </div>
  );
};

export default Register;
