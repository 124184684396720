import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { resetPassword } from "../../services/api"; // API function for resetting password
import styles from "./ResetPassword.module.css";
import logoWhite from "../../assets/arncloud-logo-white.png";

const ResetPassword = () => {
    const { token } = useParams(); // Get token from URL
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage("");
        setError("");
        if (newPassword !== confirmPassword) {
            setError("Passwords do not match.");
            return;
        }
        try {
            await resetPassword({ token, newPassword });
            setMessage("Password reset successfully. Redirecting...");
            setTimeout(() => navigate("/sign-in"), 2000); // Redirect to sign-in after 2 seconds
        } catch (err) {
            setError("Failed to reset password. Please try again.");
        }
    };

    return (
        <div className={styles.container}>
            <img className={styles.logo} src={logoWhite} alt="arncloud logo" />
            <form className={styles.form} onSubmit={handleSubmit}>
                <h1 className={styles.heading}>Reset Password</h1>
                {message && <p className={styles.success}>{message}</p>}
                {error && <p className={styles.error}>{error}</p>}
                <input
                    type="password"
                    placeholder="New Password"
                    className={styles.input}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                />
                <input
                    type="password"
                    placeholder="Confirm Password"
                    className={styles.input}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                />
                <button type="submit" className={styles.button}>
                    Reset Password
                </button>
            </form>
        </div>
    );
};

export default ResetPassword;
