import React, { useContext, useEffect, useState } from "react";
import { listFiles, deleteFile, fetchStorageInfo } from "../services/api"; // API calls
import FileUpload from "../components/FileUpload"; // File upload component
import styles from "./Dashboard.module.css";
import AuthContext from "../context/AuthContext";
import axios from "axios";

const Dashboard = () => {
  const [files, setFiles] = useState([]); // State to store file list
  const [error, setError] = useState(""); // State to store errors
  const [showUploadPopup, setShowUploadPopup] = useState(false); // State to toggle popup visibility
  const [storage, setStorage] = useState({
    used_storage: 0, // Initialize to 0
    max_storage: 1, // Initialize to prevent division by 0
  });

  const { auth } = useContext(AuthContext);

  // Fetch the files when the component loads
  const fetchFiles = async () => {
    try {
      const { data } = await listFiles();
      setFiles(data);
    } catch (err) {
      setError(err.response?.data?.message || "Failed to fetch files.");
    }
  };

  const getStorageData = async () => {
    try {
      const data = await fetchStorageInfo(); // Use API function

      setStorage(data.data);
    } catch (err) {
      console.log(err);
      setError("Failed to fetch storage information.");
    }
  };

  useEffect(() => {
    fetchFiles();
    getStorageData();
  }, []);

  // Handle file deletion
  const handleDelete = async (fileId) => {
    try {
      await deleteFile(fileId);
      setFiles(files.filter((file) => file.id !== fileId)); // Remove deleted file from state
    } catch (err) {
      alert("Failed to delete the file.");
    }
  };

  const { used_storage, max_storage } = storage;
  const usagePercentage = ((used_storage / max_storage) * 100).toFixed(1); // Calculate percentage

  const formatBytes = (bytes) => {
    if (bytes === 0) return "0 Bytes";
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sizes[i]}`;
  };

  return (
    <div className={styles.container}>
      <div className={styles.storageBar}>
        <div className={styles.usage}>
          <span>{formatBytes(used_storage)}</span> /{" "}
          <span>{formatBytes(max_storage)}</span> ({usagePercentage}%)
        </div>
        <div className={styles.progressBar}>
          <div
            className={styles.progress}
            style={{ width: `${usagePercentage}%` }}
          ></div>
        </div>
      </div>
      <div className={styles.header}>
        <h1 className={styles.heading}>Dashboard</h1>
        <button
          className={styles.uploadButton}
          onClick={() => setShowUploadPopup(true)}
        >
          +
        </button>
      </div>
      {error && <p className={styles.error}>{error}</p>}

      {files.length > 0 ? (
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Filename</th>
              <th>Size</th>
              <th>Uploaded At</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {files.map((file) => (
              <tr key={file.id}>
                <td>{file.filename}</td>
                <td>{(file.size / 1024).toFixed(2)} KB</td>
                <td>{new Date(file.uploaded_at).toLocaleString()}</td>
                <td>
                  <button
                    onClick={() => handleDelete(file.id)}
                    className={styles.deleteButton}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className={styles.noFiles}>No files available.</p>
      )}

      {/* Popup for file upload */}
      {showUploadPopup && (
        <div className={styles.popupOverlay}>
          <div className={styles.popup}>
            <h2>Upload Files</h2>
            <FileUpload onFileUploaded={fetchFiles} />
            <button
              className={styles.closeButton}
              onClick={() => setShowUploadPopup(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
