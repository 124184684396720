import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { uploadFile } from "../services/api"; // API call for uploading
import styles from "./FileUpload.module.css";

const FileUpload = ({ onFileUploaded }) => {
  const [isUploading, setIsUploading] = useState(false); // To show upload progress
  const [uploadProgress, setUploadProgress] = useState(0); // Progress percentage
  const [error, setError] = useState(""); // For error messages

  // Handle file drop
  const onDrop = async (acceptedFiles) => {
    setError(""); // Clear previous errors
    setIsUploading(true); // Start upload indicator
    setUploadProgress(0); // Reset progress

    try {
      const formData = new FormData();
      acceptedFiles.forEach((file) => formData.append("file", file));

      await uploadFile(formData, (progressEvent) => {
        // Calculate the upload progress percentage
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setUploadProgress(progress); // Update progress
      });

      onFileUploaded(); // Notify parent component to refresh file list
    } catch (err) {
      setError("Failed to upload file(s).");
    } finally {
      setIsUploading(false); // Stop upload indicator
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div className={styles.container}>
      <div {...getRootProps({ className: styles.dropzone })}>
        <input {...getInputProps()} />
        <p>Drag & drop files here, or click to select files</p>
      </div>
      {isUploading && (
        <div className={styles.progressContainer}>
          <div
            className={styles.progressBar}
            style={{ width: `${uploadProgress}%` }}
          ></div>
          <span className={styles.progressText}>{uploadProgress}%</span>
        </div>
      )}
      {error && <p className={styles.error}>{error}</p>}
    </div>
  );
};

export default FileUpload;
