import React, { useContext, useState } from "react";
import styles from "./Login.module.css";
import logoWhite from "../assets/arncloud-logo-white.png";
import { login } from "../services/api";
import AuthContext from "../context/AuthContext";
import { Navigate } from "react-router-dom";

const Login = () => {
  const [username, setUsername] = useState(""); // State for username input
  const [password, setPassword] = useState(""); // State for password input
  const [error, setError] = useState(""); // State for error messages
  const { auth, login: setAuth } = useContext(AuthContext); // Context to store auth token and role

  if (auth.token) {
    // Redirect to dashboard if already logged in
    return <Navigate to="/dashboard" />;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(""); // Clear any previous error

    try {
      const { data } = await login({ username, password }); // Call backend API
      setAuth(data.token, data.user.role, data.user.username); // Save token and role in context/localStorage
    } catch (err) {
      // Capture error from the API response
      setError(
        err.response?.data?.message || "An error occurred. Please try again."
      );
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.mainContainer}>
        {/* Left Section */}
        <div className={styles.leftSection}>
          <div className={styles.brand}>
            <img className={styles.logo} src={logoWhite} alt="arncloud logo" />
          </div>
          <ul className={styles.features}>
            <li>
              <span>⚙️</span>
              <div>
                <h3>Adaptable performance</h3>
                <p>
                  Our product effortlessly adjusts to your needs, boosting
                  efficiency and simplifying your tasks.
                </p>
              </div>
            </li>
            <li>
              <span>🛠️</span>
              <div>
                <h3>Built to last</h3>
                <p>
                  Experience unmatched durability that goes above and beyond
                  with lasting investment.
                </p>
              </div>
            </li>
            <li>
              <span>👍</span>
              <div>
                <h3>Great user experience</h3>
                <p>
                  Integrate our product into your routine with an intuitive and
                  easy-to-use interface.
                </p>
              </div>
            </li>
            <li>
              <span>✨</span>
              <div>
                <h3>Innovative functionality</h3>
                <p>
                  Stay ahead with features that set new standards, addressing
                  your evolving needs better than the rest.
                </p>
              </div>
            </li>
          </ul>
        </div>

        {/* Right Section */}
        <div className={styles.rightSection}>
          <div className={styles.formContainer}>
            <h2>Sign in</h2>
            <form onSubmit={handleSubmit}>
              <label>Username</label>
              <input
                type="username"
                placeholder="john.doe"
                className={styles.input}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
              <label>Password</label>
              <input
                type="password"
                placeholder="•••••••"
                className={styles.input}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />

              <div className={styles.rememberSection}>
                <a href="/forgot-password">Forgot your password?</a>
              </div>
              <button type="submit" className={styles.signInButton}>
                Sign in
              </button>

              {error && <p className={styles.error}>{error}</p>}
            </form>
            <div className={styles.signup}>
              <p>
                Don’t have an account? <a href="/register">Register</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
