import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";

const ProtectedRoute = ({ children }) => {
    const { auth } = useContext(AuthContext);

    if (!auth.token) {
        // If no token, redirect to login
        return <Navigate to="/" />;
    }

    return children;
};

export default ProtectedRoute;
